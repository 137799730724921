import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { CompleteTermsSection } from "./CompleteTerms.styles"
import { LINKS } from "../../../data/pageLinks.data"
import { Link } from "gatsby"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "./SuperResponsiveTableStyle.css"

const CompleteTerms = () => {
  const [toggleVisibility, setToggleVisibility] = useState(false)
  return (
    <CompleteTermsSection className="py-5 px-4 px-lg-0">
      <Container>
        <section>
          <h2 className="h1 my-5 text-uppercase secondary-blue-2-text">
            PRIVACY POLICY
          </h2>
          <p>
            APEXX Fintech Limited (“<strong>APEXX</strong>”, “<strong>we</strong>”, “<strong>our</strong>” or
            “<strong>us</strong>”) is committed to protecting your privacy and the security of your personal data. This
            privacy policy will inform you how we collect, use and protect your personal data when you visit our website
            www.apexx.global.com, become (or are in the process of becoming) an APEXX client or partner, or if we
            receive personal data about you for recruitment purposes. Beyond this privacy policy, your use of our
            services is also subject to our Terms of Service.
          </p>
          <p>
            If you are a U.S. resident, please also review our U.S. Resident Privacy Notice for more information
            about the types of personal data we collect and disclose, as well as how to exercise your rights under U.S.
            law.
          </p>
          <section>
            <h3 className="my-5 text-uppercase primary-blue-text">
              1. WHO WE ARE
            </h3>
            <p>
              APEXX is a company registered in the United Kingdom, under company number 10131902, with its registered
              address at First Floor, 69 Turnmill Street, Farringdon, London EC1M 5RR.
            </p>
            <p>
              We are responsible for the overall processing of your personal data and are a ‘data controller, which
              means we are responsible for your personal data unless otherwise stated. Our registration number with the
              Information Commissioner’s Office (ICO) is ZA267133. Our appointed Data Protection Officer (“DPO”) who is
              responsible for overseeing questions in relation to this privacy policy, is FTI Consulting at 200
              Aldersgate, Aldersgate Street, London, EC1A 4HD.
            </p>
          </section>
          <section>
            <h3 className="my-5 text-uppercase primary-blue-text">
              2. THE PERSONAL DATA WE MAY COLLECT FROM YOU{" "}
            </h3>
            <p>
              Most of the personal data we collect about you is provided to us directly by you, for example when you:
            </p>
            <ul>
              <li>Browse our website;</li>
              <li>Contact us directly, either via the contact form on our website, by email or phone;</li>
              <li>Request or register to receive information from us;</li>
              <li>Become an APEXX client;</li>
              <li>Create an account on our platform;</li>
              <li>Process a payment through our platform;</li>
              <li>Apply for an employment vacancy on our site; or</li>
              <li>Accept the use of Cookies on our site.</li>
            </ul>
            <p>
              In order to understand more about you and to verify your identity, we may supplement and combine the
              personal data that we collect from you with other categories of data obtained from third parties or
              publicly available sources.
            </p>
            <p>
              The personal data we may collect about you includes:
            </p>
            <ul>
              <li>Identification information including title, name, date of birth, gender, marital status, job
                title/position, education, employment history, professional account details (e.g. LinkedIn), your
                passport or other official forms of identification;
              </li>
              <li>Contact information including address, telephone number, email address and marketing preferences;</li>
              <li>Pre-employment vetting information including verification of address and qualifications, references,
                official forms of identification and right to work status;
              </li>
              <li>Financial and transactional information including bank account details, identifiers (e.g. National
                Insurance numbers) and payment card information;
              </li>
              <li>Identifiers assigned to your computer or other devices, including your device manufacturer and model,
                Internet Protocol (IP) address, online registration details and login credentials, browser type and
                version, time zone setting and location, browser plug-in types and versions, add-ons and the language
                version of the website you are visiting, operating system and platform;
              </li>
              <li>Usage information, including pages or other content you view, purchases you make, and the dates and
                times of your visits; and
              </li>
              <li>Any other information relating to you which you may provide to us.</li>
            </ul>
            <p>
              By using our website, cookies may also be stored on your devices. More information on our use of cookies
              can be found in our <Link href="#cookies-policy" className="secondary-blue-2-text">
                Cookies Policy
              </Link>.
            </p>
            <p>
              When we send you emails, we may track whether you open them to learn how to deliver a better customer
              experience and improve our services.
            </p>
            <p>
              We also anonymise and aggregate personal data (meaning you are not identifiable) and use it for other
              purposes including research, data analysis, improving our website, testing our IT systems, improving our
              products and services or developing new products and services.
            </p>
            <p>
              We do not intentionally collect any Special Categories of Personal Data (sensitive personal data) about
              you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life,
              sexual orientation, political opinions, trade union membership, information about your health, and genetic
              and biometric data). Nor do we collect any information about criminal convictions and offences.
            </p>
            <p>
              Where we need to collect personal data by law, or under the terms of a contract we have with you, and you
              fail to provide that information when requested, we may not be able to perform the contract we have or are
              trying to enter into with you (for example, to provide you with our products or services). In this case,
              we may have to cancel a product or service you have with us, but we will notify you if this is the case at
              the time.
            </p>
            <p>
              <u>Children’s privacy protection</u>
            </p>
            <p>
              Our services are not designed for, or intentionally targeted at, anyone under the age of 18. We do not
              intentionally collect or maintain data about anyone under this age. If we become aware that we have
              collected Personal Data from someone under the age of 18, we will promptly delete it, unless we are
              legally obligated to retain such data. If you learn that a child has provided us with personal data in
              violation of this privacy policy, then you may alert us at <Link href="mailto:privacy@apexx.global" className="secondary-blue-2-text">
                privacy@apexx.global
              </Link>.
            </p>
          </section>
          <section>
            <h3 className="my-5 text-uppercase primary-blue-text">
              3. HOW WE USE THE INFORMATION{" "}
            </h3>
            <p>
              We will only use your personal data when the law allows us to. Examples of how we will use your personal
              data include:
            </p>
            <ul>
              <li>Assessing your enquiry to become an APEXX merchant or partner (as is necessary for our legitimate
                interests);
              </li>
              <li>Verifying your identity and conducting anti-fraud and anti-money laundering checks including without
                limitation KYC (Know-Your-Customer) and KYB (Know-Your Business) obligations and other financial
                reporting obligations mandated under law or by our payment service partners who may require us to report
                our compliance to third parties and submit to third party verification audits (as is necessary for our
                legitimate interests and/or as is necessary to comply with a legal obligation);
              </li>
              <li>As necessary for the performance of a contract to which you are a party or as required before entering
                into such a contract (as is necessary for our legitimate interests and/or as is necessary for
                performance of a contract between you and APEXX);
              </li>
              <li>For accounting, auditing, and billing activities (as is necessary for our legitimate interests);</li>
              <li>Providing you with any information that you request from us, including information on our company,
                products and services (as is necessary for our legitimate interests);
              </li>
              <li>Providing you with information about other products and services we offer that are similar to those
                you have already purchase or enquired about (as is necessary for our legitimate interests);
              </li>
              <li>Responding to any queries or support requests in respect of our products and services and also
                notifying you about any changes to our products and services and/or terms and conditions or policies (as
                is necessary for our legitimate interests, as is necessary for performance of a contract between you and
                APEXX and/or as is necessary to comply with a legal obligation);
              </li>
              <li>Managing and improving customer relationships and experiences (as is necessary for our legitimate
                interests and/or as is necessary for performance of a contract between you and APEXX);
              </li>
              <li>For legitimate human resources requirements including evaluating candidates for potential employment
                and storing resumes of unsuccessful applications for future vacancies in accordance with our Data
                Retention Policy (as is necessary for our legitimate interests);
              </li>
              <li>Ensuring the content from our website is presented in the most effective manner for you and your
                computer and to continually improve our website (as is necessary for our legitimate interests);
              </li>
              <li>Complying with obligations owed to a relevant authority (including taxation obligations) or regulator
                (as is necessary for compliance with a legal obligation and/or as is necessary for our legitimate
                interests)
              </li>
              <li>Conducting aggregate analysis and developing business intelligence that enables us to operate,
                protect, make informed decisions, and report on the performance of our business;
              </li>
              <li>Undertaking data analysis and research to improve our website, products and services and for
                developing new products and services tailored to our customers’ needs (as is necessary for our
                legitimate interests); and
              </li>
              <li>Administering and protecting our business and this website, including troubleshooting, testing, system
                maintenance, support (as is necessary for our legitimate interests and/or as is necessary for compliance
                with a legal obligation);
              </li>
              <li>Finding and preventing fraud and abuse, and responding to trust and safety issues that may arise;</li>
              <li>Facilitating transactions and payments; and</li>
              <li>For other purposes for which we provide specific notice at the time the information is collected.</li>
            </ul>
            <p>
              <strong>Legitimate Interest</strong> means the interest of our business in conducting and managing our
              business in an effective, sustainable and customer focused way that enables us to give you the best
              products and services and the best and most secure experience while also protecting our business and
              always acting in compliance with applicable laws. We make sure we consider and balance any potential
              impact on you (both positive and negative) and your rights before we process your personal data for our
              legitimate interests. We do not use your personal data for activities where our interests are overridden
              by the impact on you (unless we have your consent or are otherwise required or permitted by law). You can
              obtain further information about how we assess our legitimate interests against any potential impact on
              you in respect of specific activities by contacting us.
            </p>
            <p>
              Generally, we do not rely on consent as a legal basis for processing your personal data although we will
              obtain your consent before sending direct marketing communications to you via email including your express
              consent before we share your personal data with any third party for marketing purposes. You have the right
              to withdraw consent and unsubscribe from receiving these marketing communications at any time by following
              the instructions provided in the communication or otherwise <Link href="mailto:privacy@apexx.global" className="secondary-blue-2-text">
                contacting us
              </Link>.
              Even if you opt out of receiving marketing communications from us, you will continue to receive
              administrative messages from us.
            </p>
            <p>
              Note that we may process your personal data for more than one lawful ground depending on the specific
              purpose for which we are using your data. Please contact us if you need details about the specific legal
              grounds we are relying on to process your personal data.
            </p>
          </section>
          <section>
            <h3 className="my-5 text-uppercase primary-blue-text">
              4. SHARING YOUR PERSONAL DATA{" "}
            </h3>
            <p>
              We may share your personal data with third parties as set out below for the purposes set out in this
              policy:
            </p>
            <ul>
              <li>Any current or future affiliates;</li>
              <li>Service providers who provide identity and compliance verification services, website hosting, data
                analysis, IT support and system administration services;
              </li>
              <li>Professional advisers including lawyers, bankers, auditors and insurers who provide consultancy,
                banking, legal, insurance and accounting services;
              </li>
              <li>Payment service providers as is necessary for the performance of a contract between you and APEXX;
              </li>
              <li>HM Revenue & Customs, regulators and other authorities acting who require reporting of processing
                activities in certain circumstances;
              </li>
              <li>Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets.
                Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our
                business, then the new owners may use your personal data in the same way as set out in this privacy
                policy; and
              </li>
              <li>Where we are under a duty to disclose to comply with any legal obligation or to protect the rights,
                property or safety of APEXX, our customers or other third parties. This includes exchanging information
                for the purposes of fraud protection and credit risk reduction and complying with any legal orders for
                disclosure and any requests from other public or governmental authorities.
              </li>
            </ul>
            <p>
              We require all third parties to respect the security of your personal data and to treat it in accordance
              with the law. We do not allow our third-party service providers to use your personal data for their own
              purposes and only permit them to process your personal data for specified purposes and in accordance with
              our instructions.
            </p>
            <p>
              If you would like further details regarding the third parties with whom we may share your personal data,
              or how they use your data, please contact us.
            </p>
          </section>
          <section>
            <h3 className="my-5 text-uppercase primary-blue-text">
              5. INTERNATIONAL TRANSFERS{" "}
            </h3>
            <p>
              Sharing your personal data with third parties (as presented in this policy) may mean your personal data is
              transferred to and processed in a destination that is outside the United Kingdom or the European Economic
              Area (EEA). It may also be processed by staff operating outside the UK or the EEA who work for APEXX or
              for one of our third-party suppliers.
            </p>
            <p>
              Whenever we transfer your personal data out of the UK or the EEA, we ensure a similar degree of protection
              is afforded to it by ensuring at least one of the following safeguards is implemented:
            </p>
            <ul>
              <li>The country that we transfer personal data to is approved by the ICO or European Commission as
                providing an adequate level of protection for personal data. For further details, please refer to the
                ICO or European Commission websites on ‘Adequacy Decisions’.
              </li>
              <li>We have implemented additional safeguards to ensure the same level of protection is afforded to the
                personal data to be transferred outside the UK or EEA including conducting a data transfer risk
                assessment and ensuring the recipient agrees to enhanced contractual undertakings (for example, the ICO
                International Data Transfer Agreement or the International Data Transfer Addendum to the European
                Commission’s standard contractual clauses for international data transfers or the standard contractual
                clauses approved by the European Commission, in each case as appropriate).
              </li>
            </ul>
            <p>
              Please contact us if you want further information about transfers of your personal data out of the UK or
              the EEA and/or the specific mechanism used by us when transferring your personal data out of the UK or the
              EEA.
            </p>
          </section>
          <section>
            <h3 className="my-5 text-uppercase primary-blue-text">
              6. DATA SECURITY
            </h3>
            <p>
              We are committed to ensuring your personal data is secure and have put in place appropriate
              administrative, technical and organisational security measures to prevent unauthorised disclosure, loss or
              misuse. We maintain physical, electronic and procedural safeguards in connection with the collection,
              storage and disclosure of personal data. . However, because no electronic transmission or storage of
              information can be entirely secure, we can make no guarantees as to the security or privacy of your
              personal data.
            </p>
            <p>
              We follow the Payment Card Industry Data Security Standards (PCI DSS) when handling credit card data and
              are PCI DSS Level 1 compliant. We are also ISO 27001 certified demonstrating our commitment to adopting a
              best practice approach to information security.
            </p>
            <p>
              We have put in place policies and procedures to respond in the event of any suspected or actual personal
              data breach and will notify you and any applicable regulator of a breach where we are legally required to
              do so.
            </p>
          </section>
          <section>
            <h3 className="my-5 text-uppercase primary-blue-text">
              7. HOW LONG WILL YOU RETAIN MY PERSONAL DATA?{" "}
            </h3>
            <p>
              We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we
              collected it for (as stated in this policy), including for the purposes of satisfying any legal,
              regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer
              period in the event of a complaint or if we reasonably believe there is a prospect of litigation in
              respect to our relationship with you.
            </p>
            <p>
              To determine the appropriate retention period for personal data, we consider the amount, nature and
              sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your
              personal data, the purposes for which we process your personal data and whether we can achieve those
              purposes through other means, and also the applicable legal, regulatory, tax, accounting or other
              requirements.
            </p>
            <p>
              Where you have consented to use of your personal data for direct marketing communications, we will keep
              such data until you notify us otherwise or withdraw such consent.
            </p>
            <p>
              In some circumstances you can ask us to delete your personal data. See ‘Your Rights’ below for further
              information.
            </p>
            <p>
              We may anonymise your personal data (so that it can no longer be associated with you) for research or
              statistical purposes, in which case we may use this information indefinitely without further notice to
              you.
            </p>
          </section>
          <section>
            <h3 className="my-5 text-uppercase primary-blue-text">
              8. YOUR RIGHTS
            </h3>
            <p>
              Under data protection laws, you have the following rights in relation to your personal data:
            </p>
            <ul class="my-5">
              <li><strong>Access</strong> – you have the right to obtain access to your personal data (known as a “data
                subject access request”). This enables you to receive a copy of the personal data we hold and check that
                we are lawfully processing it.
              </li>
              <li><strong>Correct</strong> – you can require us to correct the personal data we hold about you if it is
                incorrect or incomplete. Please note we may need to verify the accuracy of the new data provided.
              </li>
              <li><strong>Erase</strong> – you can ask us to delete or remove your personal data.</li>
              <li><strong>Restrict/Stop Processing</strong> – you may request that we restrict processing of your data
                or cease processing altogether. Where we have asked for your consent to process personal data, you can
                withdraw such consent at any time. Where we process your data for our legitimate interests, you can
                request information about these.
              </li>
              <li><strong>Transfer</strong> – you may request the transfer or your personal data to you or a third
                party. In such case we will provide you with your personal data in a structured, commonly used,
                machine-readable format.
              </li>
            </ul>
            <p>If you wish to exercise any of your rights listed above, please contact us</p>
            <p>
              Please note that these rights may be limited under applicable laws and are subject to technical
              feasibility.
            </p>
            <p>
              Upon receipt of your request, we may require additional information from you to verify your identify and
              process your request. This is a security measure to ensure that personal data is not disclosed to any
              person who has no right to receive it. We may also contact you to ask you for further information in
              relation to your request to speed up our response.
            </p>
            <p>
              Please note we have 30 days in which to respond to you but if your request is particularly complex or you
              have made a number of requests, it may take us longer, in which case we will notify you.
            </p>
          </section>
          <section>
            <h3 className="my-5 text-uppercase primary-blue-text">
              9. THIRD-PARTY LINKS{" "}
            </h3>
            <p>
              This website may include links to third-party websites, plug-ins and applications. Clicking on those links
              or enabling those connections may allow third parties to collect or share data about you.
            </p>
            <p>
              We do not control these third-party websites and are not responsible for, and make no representation or
              warranties regarding, the information on these websites or their privacy statements. We have no control or
              influence over what data these providers collect from you nor knowledge regarding the extent of their data
              processing. A link to a third-party website should not be construed as an endorsement by APEXX.
            </p>
            <p>
              When you leave our website, we encourage you to read the privacy policy of every third-party website you
              visit.
            </p>
          </section>
          <section>
            <h3 className="my-5 text-uppercase primary-blue-text">
              10. CONTACTING US AND COMPLAINTS
            </h3>
            <p>
              If you: (i) have any queries or concerns about how we process your information; (ii) have any feedback
              regarding our Privacy Policy; (iii) want to exercise any of your rights as set out in this Privacy Policy
              including your right to request we update or stop using your personal data; or (iv) otherwise have a
              complaint, please contact us in the following ways:
            </p>
            <p>
              Email address:
              <a href="mailto:privacy@apexx.global" className="secondary-blue-2-text">
                privacy@apexx.global
              </a>
            </p>
            <p>
              Postal address: APEXX Fintech Limited, First Floor, 69 Turnmill Street, Farringdon, London EC1M 5RR,
              United Kingdom.
            </p>
            <p>
              For data subjects located in the European Union, please see section 11 below. If you are not satisfied
              with our response, you also have the right to address your complaint to the ICO or the data protection
              authority in your country. More information on your rights under data protection legislation can be found
              on the
              <a
                className="secondary-blue-2-text"
                href={LINKS.icoWebsite}
                target="_blank"
                rel="noreferrer"
              >
                ICO website
              </a>.
            </p>
          </section>
          <section>
            <h3 className="my-5 text-uppercase primary-blue-text">
              11. REPRESENTATIVE FOR DATA SUBJECTS IN THE EUROPEAN UNION
            </h3>
            <p>
              We have appointed FTI Consulting LLP (FTI) as our privacy representative and your point of contact as a
              data subject based in the European Union.
            </p>
            <p>
              FTI gives you an easy way to exercise your rights (as noted in section 8 above). If you want to contact us
              via our representative FTI or exercise your data subject rights, please contact us in the following ways:
            </p>
            <p>
              EU Legal Representative Name: Ana Jankov<br />
              EU Legal Representative Email Address: <a href="mailto:ana.jankov@fticonsulting.com"
                                                        className="secondary-blue-2-text">
              ana.jankov@fticonsulting.com
            </a><br />
              EU Legal Representative Postal Address: Bold Capital Management BV/ FTI Consulting Eduard van Beinumstraat
              20 1077 CZ Amsterdam<br />
              EU Legal Representative Phone No. +32 460962318
            </p>
          </section>
          <section>
            <h3 className="my-5 text-uppercase primary-blue-text">
              12. CHANGES TO THIS PRIVACY POLICY AND YOUR DUTY TO INFORM US OF
              CHANGES TO YOUR PERSONAL DATA
            </h3>
            <p>
              We may update our Privacy Policy from time to time by publishing an updated version on our website. Please
              check this page on occasion to ensure you are happy with any changes. If we make significant changes, we
              will make this clear on our website or by other means of contact such as email.
            </p>
            <p>
              It is important that the personal data we hold about you is accurate and current. Please keep us informed
              if your personal data changes during your relationship with us.
            </p>
          </section>
        </section>
        <section>
          <h2
            className="h1 my-5 text-uppercase secondary-blue-2-text"
            id="cookies-policy"
          >
            COOKIES POLICY
          </h2>
          <p>
            We use cookies and similar techniques such as tags/web beacons when you visit our website to ensure you have
            the best experience, to distinguish you from other users of our website, and to ensure that the content is
            relevant and useful. This policy explains how cookies are used on this website, the options for controlling
            the use of cookies and the specific cookies that are used.
          </p>
          <p>
            This policy applies to cookies used on this website and in sales and marketing emails.
          </p>
          <h3 className="my-5 text-uppercase primary-blue-text">
            1. WHAT ARE COOKIES?
          </h3>
          <p>
            Cookies are small data files that are placed on your device (computer, smartphone, tablet etc.) when you
            visit a website. They are stored by your internet browser and may contain basic information about your
            device and internet use, but they do not normally identify you personally. They are used in order to make
            websites work, to provide features, or to work more efficiently, as well as to provide information to the
            owner of the site such as pages visited, number of visitors etc. Some cookies also enable the display of
            relevant advertising when you move from site to site.
          </p>
        </section>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">
            2. WHAT TYPES OF COOKIES DO WE USE?
          </h3>
          <p>
            The cookies we use fall into three categories, each described below:
          </p>
          <p>
            <u>Essential cookies:</u><br /><br />
            These cookies are necessary for the operation and proper functioning of our website. They do not access or
            store any personal data. Without these cookies, our website will not function properly, and you will not be
            able to use certain important features Our website so they are, by default, switched on.
          </p>
          <p>
            You can manage cookies in your browser (see Section 4 below). Your choices will include the ability to
            switch off cookies completely and to remove cookies by deleting them when you leave our website. If you do
            switch cookies off, you may find problems with the operation of our website.
          </p>
          <p>
            <u>Analytical cookies:</u><br /><br />
            These cookies help us understand how our website is being used and how we can improve your experience on it.
            These “analytical or performance” cookies can provide us with anonymous information to help us understand
            which parts of our website interest our visitors and are working well and where we can make improvements. We
            use these cookies to test different designs and features for our sites and we also use them to help us
            monitor how our visitors reach our sites. The information collected does not directly identify any
            individuals.
          </p>
          <p>
            You can control whether you want to accept the use of these cookies via the cookie consent options on the
            website.
          </p>
          <p>
            <u>Functionality cookies:</u><br /><br />
            These cookies allow us to recognize you when you return to our website. They enable us to personalise
            content for you and remember preferences you may have set on your last visit (for example your username,
            language preferences and location).
          </p>
          <p>
            <u>Targeting cookies:</u><br /><br />
            These cookies record your visit to our website, the pages you have visited and the links you have followed.
            We will use this information to make our website and the advertising displayed on it more relevant to your
            interests.
          </p>
          <p>
            You can control whether you want to accept the use of these cookies via the cookie consent options on the
            website.
          </p>
        </section>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">
            3. COOKIE DETAILS
          </h3>
          <p className="mb-5">
            Below are further details of the cookies used on our website.
          </p>

          <Table withBaseStyles={{ breakpoint: "45em" }}>
            <Thead>
              <Tr>
                <Th>Service</Th>
                <Th>Description</Th>
                <Th>Listed Cookies</Th>
                <Th>Category</Th>
                <Th>1st / 3rd Party</Th>
                <Th>Duration / Expiration</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td rowspan="3">APEXX Global</Td>
                <Td rowspan="3">
                  These cookies are used to save and store your cookie consent preferences on this website.
                </Td>
                <Td>gatsby-gdpr-google-tagmanager</Td>
                <Td>Necessary</Td>
                <Td>1st</Td>
                <Td>1 year</Td>
              </Tr>
              <Tr>
                <Td>my-cookie-necessary</Td>
                <Td>Necessary</Td>
                <Td>1st</Td>
                <Td>1 year</Td>
              </Tr>
              <Tr>
                <Td>my-cookie-analytics</Td>
                <Td>Necessary</Td>
                <Td>1st</Td>
                <Td>1 year</Td>
              </Tr>

              <Tr>
                <Td rowspan="4">Google Analytics</Td>
                <Td rowspan="4">
                  This service tracks website usage and provides insights into visitor behaviour, helping us improve
                  user experience. It collects data about page visits, duration, and user interactions. More information
                  can be found <a
                  className="secondary-blue-2-text"
                  href="https://business.safety.google/adscookies/"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>.
                </Td>
                <Td>_gid</Td>
                <Td>Analytics</Td>
                <Td>1st</Td>
                <Td>24 hours</Td>
              </Tr>
              <Tr>
                <Td>_ga</Td>
                <Td>Analytics</Td>
                <Td>1st</Td>
                <Td>2 years</Td>
              </Tr>
              <Tr>
                <Td>_ga_FE6350Y1DH</Td>
                <Td>Analytics</Td>
                <Td>1st</Td>
                <Td>2 years</Td>
              </Tr>
              <Tr>
                <Td>_gat_UA-103173302-1</Td>
                <Td>Analytics</Td>
                <Td>1st</Td>
                <Td>1 minute</Td>
              </Tr>

              <Tr>
                <Td rowspan="5">HubSpot</Td>
                <Td rowspan="5">
                  Used to monitor website traffic and to track users. Users are tracked anonymously until they fill out
                  a form on the website. More information can be found <a
                  className="secondary-blue-2-text"
                  href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>.
                </Td>
                <Td>hubspotutk</Td>
                <Td>Analytics</Td>
                <Td>1st</Td>
                <Td>6 months</Td>
              </Tr>
              <Tr>
                <Td>__hssc</Td>
                <Td>Analytics</Td>
                <Td>1st</Td>
                <Td>30 minutes</Td>
              </Tr>
              <Tr>
                <Td>__hstc</Td>
                <Td>Analytics</Td>
                <Td>1st</Td>
                <Td>6 months</Td>
              </Tr>
              <Tr>
                <Td>__hssrc</Td>
                <Td>Analytics</Td>
                <Td>1st</Td>
                <Td>Expires end of the session</Td>
              </Tr>
              <Tr>
                <Td>__cf_bm</Td>
                <Td>Analytics</Td>
                <Td>1st</Td>
                <Td>30 minutes</Td>
              </Tr>

              <Tr>
                <Td rowspan="4">Microsoft Clarity</Td>
                <Td rowspan="4">
                  Used to collect information about how visitors interact with our website such as behavioural metrics,
                  heatmaps and session recordings. More information can be found <a
                  className="secondary-blue-2-text"
                  href="https://learn.microsoft.com/en-us/clarity/setup-and-installation/cookie-list"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>.
                </Td>
                <Td>_clsk</Td>
                <Td>Analytics</Td>
                <Td>1st</Td>
                <Td>1 year</Td>
              </Tr>
              <Tr>
                <Td>ANON</Td>
                <Td>Analytics</Td>
                <Td>3rd</Td>
                <Td>6 months</Td>
              </Tr>
              <Tr>
                <Td>MUID</Td>
                <Td>Analytics</Td>
                <Td>3rd</Td>
                <Td>1 year</Td>
              </Tr>
              <Tr>
                <Td>CLID</Td>
                <Td>Analytics</Td>
                <Td>3rd</Td>
                <Td>1 year</Td>
              </Tr>

              <Tr>
                <Td rowspan="1">Google Ads Remarketing</Td>
                <Td rowspan="1">
                  Enable Google to show targeted ads to users who have already visited this site. More information can
                  be found here. <a
                  className="secondary-blue-2-text"
                  href="https://business.safety.google/adscookies/"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>.
                </Td>
                <Td>IDE</Td>
                <Td>Targeting</Td>
                <Td>3rd</Td>
                <Td>13 months</Td>
              </Tr>

              <Tr>
                <Td rowspan="4">Google Cookies</Td>
                <Td rowspan="4">
                  These cookies will appear if you are logged into Google in your browser. These are related to Google's
                  services not the services of this website. More information can be found <a
                  className="secondary-blue-2-text"
                  href="https://policies.google.com/technologies/cookies?hl=en-US"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>.
                </Td>
                <Td>NID</Td>
                <Td>Google Functionality</Td>
                <Td>3rd</Td>
                <Td>13 months</Td>
              </Tr>
              <Tr>
                <Td>SOCS</Td>
                <Td>Google Functionality</Td>
                <Td>3rd</Td>
                <Td>13 months</Td>
              </Tr>
              <Tr>
                <Td>AEC</Td>
                <Td>Google Functionality</Td>
                <Td>3rd</Td>
                <Td>6 months</Td>
              </Tr>
              <Tr>
                <Td>CONSENT</Td>
                <Td>Google Functionality</Td>
                <Td>3rd</Td>
                <Td>2 years</Td>
              </Tr>

            </Tbody>
          </Table>

          <p class="mt-5">
            * A 1st party cookie is a cookie that is controlled by us. A 3rd party cookie is a cookie that is provided
            by a third party (e.g. Google Cookies) for which we have no control over.
            <br />
            ** If a cookie does not contain an expiration date, it is considered a session cookie. Session cookies are
            stored in memory and never written to disk. When the browser closes, the cookie is permanently lost from
            this point on.
          </p>
        </section>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">
            4. HOW DO YOU MANAGE YOUR COOKIE SETTINGS FROM YOUR BROWSER
          </h3>
          <p>
            As well as setting cookie preferences using the cookie settings options when you visit the website, most
            browsers allow you to manage cookies saved on your device. To find information relating to a browser, find
            the help section of your browser and search for turning cookies on and off.
          </p>
          <p>
            How you can control cookies for some of the most common browsers are detailed below:
          </p>
          <ul>
            <li>
              <a target="_blank"
                 href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">
                Google Chrome
              </a>
            </li>
            <li>
              <a target="_blank"
                 href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
                Firefox
              </a>
            </li>
            <li>
              <a target="_blank" href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">
                Safari (Mac)
              </a>
            </li>
            <li>
              <a target="_blank" href="https://support.apple.com/en-us/HT201265">
                Safari (iPhone/ iPad)
              </a>
            </li>
            <li>
              <a target="_blank"
                 href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                Internet Explorer
              </a>
            </li>
            <li>
              <a target="_blank" href="https://support.microsoft.com/en-us/search?query=enable%20cookies%20in%20edge">
                Microsoft Edge
              </a>
            </li>
          </ul>
          <p>
            If you disable or refuse cookies (including necessary cookies), please note that some parts of this website
            may become inaccessible or not function properly.
          </p>
        </section>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">
            5. HOW DO YOU MANAGE ANALYTICAL OR PERFORMANCE COOKIES
          </h3>
          <p>
            You can opt out of anonymous tracking from third party analytical or performance cookies by clicking on the
            link of our service providers <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">here</a>.
          </p>
        </section>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">
            6. FURTHER INFORMATION ON COOKIES
          </h3>
          <p>
            If you would like any further information about the use of cookies on this website, please contact us at <a
            href="mailto:compliance@apexx.global">compliance@apexx.global</a>.
          </p>
        </section>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">
            7. CHANGES TO THIS COOKIES POLICY
          </h3>
          <p>
            We may update our Cookies Policy from time to time by publishing an updated version on our website. Please
            check this page on occasions to ensure you are happy with any changes.
          </p>
        </section>
      </Container>
    </CompleteTermsSection>
  )
}

export default CompleteTerms
