import styled from "styled-components"
import { colors } from "../../../utils/styles"

export const CompleteTermsSection = styled.section`
  color: ${colors.primaryBlue};
	thead th {
		color:#fff;
		background:#3b5Ca7;
	}
	th, td {
		padding:10px;
	}
	table, th, td {
		border:1px #3b5Ca7 solid;
	}
`
