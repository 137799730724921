import React from "react"
import Layout from "../components/Global/Layout/Layout"
import CovidUpdate from "../components/Global/Common/CovidUpdate/CovidUpdate"
import DownloadSection from "../components/PrivacyPolicy/DownloadSection/DownloadSection"
import CompleteTerms from "../components/PrivacyPolicy/CompleteTerms/CompleteTerms"
import SEO from "../components/Global/SEO/seo"

const PrivacyPolicy = () => (
  <Layout>
    <SEO page="privacy-policy" />
    <CovidUpdate/>
    <DownloadSection/>
    <CompleteTerms/>
  </Layout>
)

export default PrivacyPolicy
