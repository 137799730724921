import React from "react"
import { Container } from "react-bootstrap"
import { ApButton } from "../../Global/Common/ApButton/ApButton"
import { DownloadSectionStyles } from "./DownloadSection.styles"

const DownloadSection = () => {
  return (
    <DownloadSectionStyles className="py-5 px-4 px-lg-2 secondary-blue-3-background ">
      <Container className="terms-and-condition">
        <h1 className="mb-4">PRIVACY & COOKIES POLICY</h1>
        <h5 className=" text-white">
          Last updated: 30 May 2024.
          {/* <br />
          <br />
          To download and print this document please click here: */}
        </h5>
        {/* <ApButton variant="green" size="md">
          Download
        </ApButton> */}
      </Container>
    </DownloadSectionStyles>
  )
}

export default DownloadSection
